const messages = {
  "orgchart.keyFiguresTitle": "Standorte",
  "orgchart.node.show": "Anzeigen",
  "orgchart.heading": "Organigramm",
  "orgchart.showarchived": "Archivierte anzeigen",
  "orgchart.addChildButton": "Eine Kind-Organisationseinheit hinzufügen",
  "orgchart.addSiblingButton":
    "Eine Geschwister-Organisationseinheit hinzufügen",
  "orgchart.archived": "(archiviert)",
  "orgchart.backButton": "Zurück",
  "orgchart.prevButton": "Vorherige Organisationseinheit",
  "orgchart.nextButton": "Nächste Organisationseinheit",
  "orgUnit.newUnitHeader": "Neue Organisationseinheit",
  "orgUnit.basics": "Stammdaten",
  "orgUnit.employees": "Mitarbeiter",
  "orgUnit.qualifications": "Qualifikationen",
  "orgUnit.workschedule": "Autopläne",
  "orgUnit.permissions": "Rechte",
  "orgUnit.authorisation": "Berechtigungen",
  "orgUnit.config": "Konfiguration",
  "orgUnit.history": "Historie",
  "orgUnit.update": "geändert",
  "orgUnit.create": "angelegt",
  "orgUnit.archive": "archiviert",
  "orgUnit.restore": "wiederhergestellt",
  "orgUnit.undefined": "",
  "orgUnit.restoreButton": "Wiederherstellen",
  "orgUnit.archiveButton": "Archivieren",
  "orgUnit.deleteButton": "Löschen",
  "orgUnit.confirmDeleteHeader": "Löschen bestätigen",
  "orgUnit.confirmDeleteText":
    "Wollen Sie diese Organisationseinheit und alle archivierten Untereinheiten wirklich löschen?",
  "orgUnit.cancelButton": "Abbrechen",
  "orgUnit.who": "Benutzer",
  "orgUnit.what": "Änderung",
  "orgUnit.when": "Datum",
  "orgUnit.locationCount": "Anzahl Standorte",
  "orgUnitDetailsForm.name": "Name",
  "orgUnitDetailsForm.abbrev": "Abkürzung",
  "orgUnitDetailsForm.importKey": "Importschlüssel für die Warenwirtschaft",
  "orgUnitDetailsForm.type": "Typ der Organisationseinheit",
  "orgUnitDetailsForm.color": "Farbe",
  "orgUnitDetailsForm.showInPlanner": "Im Planer anzeigen",
  "orgUnitDetailsForm.isLocation": "Standort",
  "orgUnitDetailsForm.accounts": "Kostenstelle (DATEV)",
  "orgUnitDetailsForm.country": "Land",
  "orgUnitDetailsForm.state": "Region",
  "orgUnitDetailsForm.zoneName": "Zeitzone",
  "orgUnitDetailsForm.email": "E-Mail-Adresse",
  "orgUnitDetailsForm.saveAndSibling": "neues Geschwister",
  "orgUnitDetailsForm.saveAndChild": "neues Kind",
  "orgUnitDetailsForm.error": "Fehler im Formular",
  "orgUnitDetailsForm.error.name.required": "Ein Name ist erforderlich",
  "orgUnitDetailsForm.error.abbrev.required": "Eine Abkürzung ist erforderlich",
  "orgUnitDetailsForm.error.email": "Ungültige E-Mail.",
  "orgUnitDetailsForm.error.abbrev.length": "Abkürzung ist zu lang.",
  "orgUnitDetailsForm.error.zoneName": "Zeitzone fehlt.",
  "orgUnitDetailsForm.error.country": "Land fehlt.",
  "orgUnitDetailsForm.error.state": "Bundesland fehlt.",
  "orgUnitDetailsForm.error.isLocationDisabledOrOverQuota":
    "Die Anlage neuer Standorte ist deaktiviert, oder Sie haben die maximale Anzahl von Standorten erreicht. Bitte deaktivieren Sie die Standort Checkbox vor dem Speichern.",
  "orgUnitDetailsForm.openinghours": "Öffnungszeiten",
  "orgunitDetailsForm.qualifications": "Anwesenheits-Check",
  "orgunitDetailsForm.qualifications.placeholder":
    "Qualifikation(en) auswählen",
  "locationInfo.title": "Übergeordnete Filiale Informationen",
  organisation: "Mandant",
  country: "Land",
  region: "Region",
  district: "Bezirk",
  company: "Firma",
  branch: "Filiale",
  department: "Abteilung",
  subdepartment: "Unterabteilung",
  DE: "Deutschland",
  "DE@BW": "Baden-Württemberg",
  "DE@BY": "Bayern (Mariä Himmelfahrt)",
  "DE@BY_s": "Bayern (Mariä Himmelfahrt und Friedensfest)",
  "DE@BY_e": "Bayern",
  "DE@B": "Berlin",
  "DE@BB": "Brandenburg",
  "DE@HB": "Bremen",
  "DE@HH": "Hamburg",
  "DE@HE": "Hessen",
  "DE@NDS": "Niedersachsen",
  "DE@MV": "Mecklenburg-Vorpommern",
  "DE@NRW": "Nordrhein-Westfalen",
  "DE@RP": "Rheinland-Pfalz",
  "DE@SL": "Saarland",
  "DE@SN": "Sachsen (Fronleichnam)",
  "DE@SN_e": "Sachsen",
  "DE@SA": "Sachsen-Anhalt",
  "DE@SH": "Schleswig-Holstein",
  "DE@TH": "Thüringen (Fronleichnam)",
  "DE@TH_e": "Thüringen",
  AT: "Österreich",
  "AT@BGLD": "Burgenland",
  "AT@KTN": "Kärnten",
  "AT@NÖ": "Niederösterreich",
  "AT@OÖ": "Oberösterreich",
  "AT@SBG": "Salzburg",
  "AT@STMK": "Steiermark",
  "AT@T": "Tirol",
  "AT@VBG": "Vorarlberg",
  "AT@W": "Wien",
  CH: "Schweiz",
  "CH@AG": "Aargau",
  "CH@AG_AA_AA": "Aarau",
  "CH@AG_LB_LB": "Laufenburg",
  "CH@AG_RH_ST": "Rheinfelden - Stein (AG)",
  "CH@SZ": "Schwyz",
  "CH@BL": "Basel-Landschaft",
  "CH@BS": "Basel-Stadt",
  "CH@FR": "Freiburg",
  "CH@FR_RF": "Freiburg (reformiert)",
  "CH@ZH_w": "Zürich-Winterthur",
  "CH@SO": "Solothurn",
  "CH@SO_hdorf": "Solothurn-Haegedorf",
  "CH@BE": "Bern (Berchtoldstag)",
  "CH@LU": "Luzern",
  "CH@LU_p": "Luzern (Ostermontag und Pfingsten)",
  "CH@SH": "Schaffhausen",
  "CH@ZH_b": "Zürich (Berchtoldstag)",
  "CH@ZH": "Zürich",
  "CH@ZH_US_DU": "Zürich - Uster - Dübendorf",
  "CH@ZG": "Zug",
  "CH@ZG_ZG": "Zug - Zug",
  "CH@SG": "St. Gallen",
  "CH@AG_b": "Aargau - Bezirk Baden",
  "CH@AG_rh_f": "Aargau - Bezirk Rheinfelden (Fronleichnam)",
  "CH@AG_rh": "Aargau - Bezirk Rheinfelden",
  "CH@AG_br": "Aargau - Bezirk Bremgarten",
  "CH@AG_le": "Aargau - Bezirk Lenzburg",
  "CH@TG": "Thurgau",
  "CH@AG_zu": "Aargau - Zurzach",
  "CH@WL_BR_BG_E": "Wallis - Brig - Brig-Glis - + Erweiterung",
  "CH@WL_BR_BG": "Wallis - Brig - Brig-Glis",
  "CH@GL_GL": "Glarus",
  "CH@GB_PL": "Graubünden - Plessur",
  "CH@GB_AL_VO": "Graubünden - Albula - Vaz/Obervaz",
  "CH@GB_SS_IL": "Graubünden - Surselva - Ilanz",
  "CH@SZ_HF_FR": "Schwyz - Höfe - Freienbach",
  "CH@SZ_ES_ES": "Schwyz - Einsiedeln - Einsiedeln",
  LI: "Liechtenstein",
  "LI@LI": "Liechtenstein",
  GLC: "Griechenland",
  "GLC@GLC": "Griechenland",
  "GLC@GLC_a": "Alternativ",
  "GLC@GLC_d": "Dimitrios",
  "GLC@GLC_bs": "Befreiung +15Märtyrer",
  "GLC@GLC_th": "Thessaloniki",
  "GLC@GLC_al": "Alexandroupolis",
  "GLC@GLC_ki": "Kilkis",
  IT: "Italien",
  "IT@IT": "Italien",
  NL: "Niederlande",
  "NL@RO": "Roermond",
  SB: "Serbien",
  "SB@BE": "Belgrad",
  "Europe/Berlin": "Europe/Berlin",
  "Europe/Vienna": "Europe/Vienna",
  "Europe/Zurich": "Europe/Zurich",
  "Europe/Athens": "Europe/Athens",
  "Europe/Rome": "Europe/Rome",
  "Europe/Amsterdam": "Europe/Amsterdam",
  "Europe/Brussels": "Europe/Brussels",
  "Europe/Belgrade": "Europe/Belgrade",
  "Pacific/Auckland": "Pacific/Auckland",
  "Pacific/Niue": "Pacific/Niue",
  "Asia/Jakarta": "Asia/Jakarta",
  "Australia/Eucla": "Australia/Eucla",
  "Asia/Tokyo": "Asia/Tokyo",
}

export default messages
