export default {
  "compact-reference": "Compact Reference",
  "AI Log": "AI Interactions",
  Planung: "Planning",
  Berichte: "Reports",
  Meldungen: "Notifications",
  Verwaltung: "Management",
  Feiertage: "Holidays",
  Mandantenverwaltung: "Client Management",
  Administratoren: "Administrators",
  InfoMessages: "Info Messages",
  PackageOverview: "Packages",
  Telemetrie: "Telemetry",
  Planer: "Planner",
  Planbausteine: "Bldg. Blocks",
  Autopläne: "Autoplans",
  Zeitkonto: "Time Sheet",
  ZeitkontoBonuses: "Bonuses",
  Wochenplan: "Week Plan",
  Fehlzeitenübersicht: "Absence overview",
  Urlaubsliste: "Leave list",
  Urlaubskonto: "Leave account",
  Jahresübersicht: "Year summary",
  Organigramm: "Orgchart",
  Mitarbeiter: "Employees",
  Qualifikationen: "Qualifications",
  Kalendereintragstypen: "Calendar Entry Types",
  Tarife: "Basic Contracts",
  Berechtigungen: "Permissions",
  Kontoinformationen: "Account Info",
  "users-active": "active users",
  Offline: "Offline",
  VerySlowNetwork: "Network Problems!",
  "problem-report": "Problem Report",
  "problem-report-title-preamble": "Problems Communicating with the Server",
  "problem-report-title-expl":
    "These problems may be cause by an unreliable network, but they may also be indications of problems in the software.",
  "problem-report-title-who":
    "We recommend that you contact mina.works support about them if you think that they are not caused by the network.",
  "problem-report-title-ignore":
    "You can also simply ignore them, and optionally delete them entirely.",
  "mainmenu.missing-client": "Please select a client",
}
