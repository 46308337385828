/* eslint-disable max-len */
const messages = {
  "roles.keyFiguresTitle": "User-Defined Roles",
  "roles.usageWarning":
    "Deleting the role will remove the associated permissions for all users who were assigned this role. This would affect the following number of users:",
  "roles.heading": "Roles",
  "roles.newButton": "Add new role",
  "roles.delete": "Delete",
  "roles.expand": "more",
  "roles.default": "Default",
  "roles.addAllDefaultRoles": "Add all defaultroles",
  "roles.addSingleDefaultRole": "Add defaultrole",
  "roles.name": "Name",
  "roles.descr": "Description",
  "roles.userPermissions": "User permissions",
  "roles.generalPermissions": "General permissions",
  "roles.permissions": "Permissions",
  "roles.error.editing": "Error",
  "roles.error.missingName": "Please enter a name for the role.",
  "roles.editRole": "Edit role",
  "roles.confirmDeleteHeader": "Really delete this role?",
  "roles.customCount": "Custom roles count",
  "roles.attrDiff": "Value differs from the default",
  "roles.diffFromDefault": "The default value was {defaultRoleValue}",
  "permission.edit-clients": "Edit client",
  "permission.view-clients": "View clients",
  "permission.edit-orgunit": "Edit organisational unit",
  "permission.view-activities": "View activities",
  "permission.edit-activities": "Edit activities",
  "permission.view-demand-planning": "View demand planning",
  "permission.edit-demand-planning": "Edit demand planning",
  "permission.view-qualifications": "View qualifications",
  "permission.edit-qualifications": "Edit qualifications",
  "permission.assign-qualifications": "Assign qualifications",
  "permission.view-qualifications-assignments":
    "View qualification assignments",
  "permission.view-autoplans": "View autoplans",
  "permission.edit-autoplans": "Edit autoplans",
  "permission.view-users+employees-master-data":
    "View users and employees master data",
  "permission.edit-users+employees-master-data":
    "Edit users and employees master data",
  "permission.view-own-master-data": "View own master data",
  "permission.edit-employee-leave-request": "Make request",
  "permission.archive-users+employees": "Archive users and employees",
  "permission.delete-users+employees": "Delete users and employees",
  "permission.archive+delete-employees": "Archive and delete employees",
  "permission.view-work-contracts": "View work contracts",
  "permission.edit-work-contracts": "Edit work contracts",
  "permission.edit-vacation-sheet": "Edit vacation sheet",
  "permission.edit-lock-times": "Edit lock times",
  "permission.view-lock-times": "View lock times",
  "permission.edit-own-lock-times": "Edit own lock times",
  "permission.view-own-lock-times": "View own lock times",
  "permission.send-messages": "Send messages",
  "permission.edit-documents": "Edit documents",
  "permission.assign-roles": "Assign roles",
  "permission.edit-roles": "Edit roles",
  "permission.view-role-assignments": "View role assignments",
  "permission.view-settings": "View settings",
  "permission.edit-settings": "Edit settings",
  "permission.view-org-chart": "View org chart",
  "permission.edit-org-chart": "Edit org chart",
  "permission.view-planner": "View planner",
  "permission.show-balances-planner": "Show balances in planner",
  "permission.view-own-planner": "View own planner",
  "permission.use-planner-activities":
    "Kalendereintragstypen im Planer verwenden",
  "permission.use-protected-planner-activities":
    "Geschütze Kalendereintragstypen im Planer verwenden",
  "permission.use-planer-work-schedules": "Use work schedules in planner",
  "permission.view-events": "View events",
  "permission.edit-events": "Edit events",
  "permission.export-planning-data": "Export planning data",
  "permission.import-external-data": "Import external data",
  "permission.edit-mail-reports": "Edit mail reports",
  "permission.manage-suggestions": "Manage employee suggestions",
  "permission.view-temp-assignments": "View tempassignments",
  "permission.edit-temp-assignments": "Edit tempassignments",
  "permission.view-re-assignments": "View reassignments",
  "permission.edit-re-assignments": "Edit reassignments",
  "permission.edit-planner": "Edit planner",
  "permission.change-password": "Change password",
  "permission.view-planbuildingblocks": "View planbuildingblocks",
  "permission.edit-planbuildingblocks": "Edit planbuildingblocks",
  "permission.view-pbbtemplates": "View planbuildingblocks templates",
  "permission.edit-pbbtemplates": "Edit planbuildingblocks templates",
  "permission.view-collectiveagreements": "View contract bases",
  "permission.edit-collectiveagreements": "Edit contract bases",
  "permission.edit-leave-account": "Edit leave account",
  "permission.view-leave-account": "View leave account",
  "permission.edit-work-hours-account": "Edit work hours account",
  "permission.view-work-hours-account": "View work hours account",
  "permission.view-own-leave-account": "View own leave account",
  "permission.view-own-work-hours-account": "View own work hours account",
  "permission.view-support-button": "View support button",
  "permission.view-user-reports": "View reports",
  "permission.view-client-master-data": "View client master data",
  "permission.view-colleague-planner": "View planner of colleagues",
  "permission.edit-own-logged-times": "Edit own logged times",
  "permission.punch-in-to-log-times": "Punch in to log times",
  "permission.view-vacation-overview-details":
    "Show partial vacation calculations",
  "permission.edit-retroactive-limits": "Edit retroactive limits",
  "permission.ignore-retroactive-limits": "Ignore retroactive limits",
}

export default messages
